import React, { useState } from "react";
import css from "@styled-system/css";

import { Avatar, Box, Card, Heading, Text } from "@tillen/fresco";

// Card to display a featured charity
function CharityCard({ name, focus, color, logo, onClick }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Box>
      <Card
        className={isHovering === true ? "isHovering" : ""}
        pt="layout.2"
        px="layout.2"
        pb="layout.2"
        height="100%"
        bg={color}
        elevation={0}
        css={css({
          cursor: "pointer",
          transition: "box-shadow 200ms ease-out",
          "&.isHovering": {
            boxShadow: "elevations.2"
          }
        })}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={onClick}
      >
        <Avatar
          image={logo && logo.replace("/upload/", "/upload/w_64,q_90/")}
          name={name}
          size={48}
          color="gray.6"
          bg="gray.1"
        />
        <Heading size={500} mt="layout.2">
          {name}
        </Heading>
        <Text size={400} mt="spacing.1" color="gray.7">
          {focus}
        </Text>
      </Card>
    </Box>
  );
}

export default CharityCard;
