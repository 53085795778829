import React from "react";
import Helmet from "react-helmet";
import { useCookies } from "react-cookie";
import Loadable from "react-loadable";
import ReactDOM from "react-dom";
import { ThemeProvider } from "emotion-theming";
import { GlobalStyles, Theme } from "@tillen/fresco";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { ApolloProvider } from "@apollo/react-hooks";
import { Global } from "@emotion/core";
import css from "@styled-system/css";
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from "apollo-cache-inmemory";

import ApolloClient from "apollo-boost";

import { Loading, ColorMode } from "components";
import { AuthProvider } from "contexts";

import "./inter.css";

import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

const DashboardLoadable = Loadable({
  loader: () => import("./pages/dashboard/"),
  loading: Loading
});

function AppInner() {
  const [cookies, setCookie, removeCookie] = useCookies(["xsrfToken"]);
  const client = new ApolloClient({
    cache: cache,
    credentials: "include",
    uri: process.env.REACT_APP_API_URL + "/graphql",
    request: operation => {
      operation.setContext({
        headers: {
          xsrfToken: cookies.xsrfToken
        }
      });
    }
  });
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ThemeProvider theme={Theme}>
          <ColorMode mode="light">
            <>
              <Helmet>
                <title>Admin | Tillen</title>
              </Helmet>
              <GlobalStyles />
              <Global
                styles={css({
                  body: { bg: "gray.0" }
                })}
              />
              <Switch>
                <Route path="/" component={DashboardLoadable} />
              </Switch>
            </>
          </ColorMode>
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

class App extends React.Component {
  render() {
    return (
      <Router>
        <CookiesProvider>
          <AppInner {...this.props} />
        </CookiesProvider>
      </Router>
    );
  }
}
ReactDOM.render(<App />, document.getElementById("root"));
