import React, { useState } from "react";
import { Link } from "react-router-dom";
import css from "@styled-system/css";

import { Box, Card, Flex, Heading, Icon, Text } from "@tillen/fresco";
import { mdiCheckCircleOutline } from "@mdi/js";

import { resizeCloudinaryUrl } from "utils";

function CauseCard({ active, heading, description, image, to, onClick }) {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Box
      as={to && Link}
      to={to}
      style={{ textDecoration: "none" }}
      onClick={onClick}
    >
      <Card
        position="relative"
        className={
          isHovering === true ? "isHovering" : "" + (active ? " active" : "")
        }
        height="100%"
        pt="layout.7"
        px="layout.2"
        pb="layout.2"
        bg="gray.3"
        elevation={0}
        css={css({
          cursor: "pointer",
          transition: "box-shadow 200ms ease-out",
          svg: {
            display: "none"
          },
          "&.isHovering": {
            boxShadow: "elevations.2"
          },
          "&.active": {
            boxShadow: "elevations.2",
            svg: {
              display: "initial"
            }
          }
        })}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Box
          as="img"
          position="absolute"
          top="0"
          left="0"
          src={image && image.url && resizeCloudinaryUrl(image.url, "sm")}
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
        />

        <Box position="relative">
          <Flex alignItems="center">
            <Heading
              size={500}
              mr="layout.1"
              color={image && image.url ? "white" : ""}
            >
              {heading}
            </Heading>
            <Icon symbol={mdiCheckCircleOutline} size={16} />
          </Flex>
          <Text
            size={400}
            mt="spacing.1"
            color={image && image.url ? "white" : "gray.7"}
          >
            {description}
          </Text>
        </Box>
      </Card>
    </Box>
  );
}

export default CauseCard;
