import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
import { Box, Flex, Heading, Icon, Paragraph } from "@tillen/fresco";
import { mdiChevronDown } from "@mdi/js";

function AccordionGroup({ id, items, onChange, ...rest }) {
  return (
    <Accordion allowZeroExpanded={true} {...rest}>
      {items.map((item, index) => {
        if (item) {
          return (
            <AccordionItem
              id={item.id}
              uuid={item.uuid}
              style={{ marginBottom: "24px" }}
              onClick={() => {
                if (onChange) {
                  onChange(item.object);
                }
              }}
              key={"accordionGroup" + id + index}
            >
              <Box
                borderRadius={24}
                // bg="gray.1"
                boxShadow="elevations.0"
                overflow="hidden"
              >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <Flex
                      px={["layout.3", "layout.6"]}
                      py={["layout.3", "layout.5"]}
                      css={{ cursor: "pointer" }}
                    >
                      <Box flex={1}>
                        <Heading children={item.heading} as="span" size={800} />
                        <Paragraph size={500} mt="layout.2" color="gray.7">
                          {item.description}
                        </Paragraph>
                      </Box>

                      <Box ml="layout.1">
                        <Icon
                          symbol={mdiChevronDown}
                          size={32}
                          color="gray.7"
                        />
                      </Box>
                    </Flex>
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel>
                  <Box
                    mx={["layout.3", "layout.6"]}
                    py={["layout.3", "layout.5"]}
                    borderTop="1px solid"
                    borderColor="gray.3"
                  >
                    {item.children}
                  </Box>
                </AccordionItemPanel>
              </Box>
            </AccordionItem>
          );
        } else {
          return null;
        }
      })}
    </Accordion>
  );
}

AccordionGroup.defaultProps = {
  color: "text.default",
  id: "accordion",
  items: []
};

export default AccordionGroup;
