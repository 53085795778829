import React from "react";
import { Box } from "@tillen/fresco";

function Wrapper({ children, maxWidth, ...rest }) {
  return (
    <Box as="section" px={["layout.2", "layout.4"]} {...rest}>
      <Box width="100%" maxWidth={maxWidth} mx="auto">
        {children}
      </Box>
    </Box>
  );
}

Wrapper.defaultProps = {
  maxWidth: 960
};

export default Wrapper;
