import React, { createContext, useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useCookies } from "react-cookie";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();
export const AuthProvider = AuthContextInner;

const GET_USER = gql`
  {
    user {
      _id
      username
      name
      profilePicture {
        url
      }
      email
      directFunds
      donationsMatched
      admin
    }
  }
`;

function AuthContextInner({ children }) {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["xsrfToken"]);
  const [getUser, { called, loading, data }] = useLazyQuery(GET_USER);

  useEffect(() => {
    if (!cookies.xsrfToken) {
      window.location.href = process.env.REACT_APP_SITE_URL;
    } else {
      getUser();
    }
  }, [cookies.xsrfToken]);

  //if user is not authorized, kick out to root tillen
  useEffect(() => {
    if (data && data.user && !data.user.admin) {
      window.location.href = process.env.REACT_APP_SITE_URL;
    }
  }, [data]);

  const logout = () => {
    removeCookie("xsrfToken", {
      path: "/",
      domain: process.env.REACT_APP_SITE_ROOT,
      secure: process.env.NODE_ENV !== "development" ? true : false,
      sameSite: "Strict"
    });
    fetch(process.env.REACT_APP_API_URL + "/graphql", {
      method: "POST",
      credentials: "include", // send cookies
      headers: {
        XSRFToken: cookies.xsrfToken,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        query: `
          mutation logout {
            logout {
              success
            }
          }
        `
      })
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user: data && data.user,
        logout: logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export const useAuth = () => useContext(AuthContext);
