const resizeCloudinaryUrl = (inputUrl, size) => {
  let url = inputUrl;
  if (url) {
    switch (size) {
      case "xl": {
        return url.replace("/upload/", "/upload/w_2560,q_80/");
      }
      case "lg": {
        return url.replace("/upload/", "/upload/w_1800,q_80/");
      }
      case "md": {
        return url.replace("/upload/", "/upload/w_900,q_80/");
      }
      case "sm": {
        return url.replace("/upload/", "/upload/w_600,q_80/");
      }
      case "xs": {
        return url.replace("/upload/", "/upload/w_300,q_80/");
      }
      case "xxs": {
        return url.replace("/upload/", "/upload/w_128,q_80/");
      }
      case "xxxs": {
        return url.replace("/upload/", "/upload/w_60,q_80/");
      }
      default: {
        return url.replace("/upload/", "/upload/w_900,q_80/");
      }
    }
  } else {
    return "";
  }
};

export default resizeCloudinaryUrl;
