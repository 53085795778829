import React from "react";
import { Global } from "@emotion/core";
import css from "@styled-system/css";
import { ellipsis } from "polished";
import {
  Menu,
  MenuList,
  MenuButton,
  MenuLink,
  MenuItem
} from "@reach/menu-button";
import {
  AspectRatio,
  Avatar,
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  Toggle
} from "@tillen/fresco";
import {
  mdiMoonWaningCrescent,
  mdiLogout,
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiFileDocumentBoxOutline
} from "@mdi/js";

import { useAuth } from "contexts";
import { resizeCloudinaryUrl } from "utils";

function Item({ icon, label, link, onClick }) {
  return (
    <MenuLink
      as="a"
      href={link}
      style={{ textDecoration: "none", cursor: "pointer" }}
      onClick={onClick}
    >
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        p="layout.1"
        borderRadius={6}
      >
        <Box mr="layout.1" p="6px" borderRadius={9999} bg="gray.3">
          <Icon symbol={icon} size={[16, 20]} color="gray.8" />
        </Box>
        <Text as="div" size={400} flex={1} color="gray.9">
          {label}
        </Text>
      </Flex>
    </MenuLink>
  );
}

function UserMenu() {
  const { user, logout } = useAuth();

  const ELEMENT_HEIGHT = 36;

  return (
    <>
      {/* Styles for menu */}
      <Global
        styles={css({
          "[data-reach-menu-button]": {
            outline: "none"
          },
          "[data-reach-menu-list]": {
            outline: "none"
          },
          "[data-reach-menu-items]": {
            outline: "none"
          },
          "[data-reach-menu-link]": {
            outline: "none"
          },
          "[data-reach-menu-item]": {
            outline: "none"
          },
          "[data-reach-menu-link][data-selected]": {
            "> *": { boxShadow: "elevations.0" }
          },
          "[data-reach-menu-item][data-selected]": {
            "> *": { boxShadow: "elevations.0" }
          }
        })}
      />

      {/* Component */}
      <Box as={Menu}>
        <Flex
          as={MenuButton}
          alignItems="center"
          m={0}
          p={0}
          border={0}
          borderRadius={0}
          bg="transparent"
          css={{ appearance: "none", cursor: "pointer" }}
        >
          <Avatar
            size={ELEMENT_HEIGHT}
            name={user && user.name}
            image={
              user &&
              user.profilePicture &&
              resizeCloudinaryUrl(user.profilePicture.url, "xxxs")
            }
          />
        </Flex>
        <Box
          as={MenuList}
          minWidth={224}
          maxWidth={224}
          mt="layout.1"
          p="layout.1"
          borderRadius={8}
          bg="gray.0"
          boxShadow="elevations.4"
        >
          <Box
            as="a"
            href={
              process.env.REACT_APP_SITE_URL +
              "/user/" +
              (user && user.username)
            }
            display="block"
            mt="-7px"
            mx="-7px"
            borderTopRightRadius={8}
            borderTopLeftRadius={8}
            overflow="hidden"
          >
            <AspectRatio ratio="4:1" bg="gray.0">
              {user && user.bannerPicture && user.bannerPicture.url && (
                <img
                  src={resizeCloudinaryUrl(user.bannerPicture.url, "xs")}
                  height="100%"
                  width="100%"
                  style={{ objectFit: "cover" }}
                />
              )}
            </AspectRatio>
          </Box>
          <Flex flexDirection="column" alignItems="center">
            <Box
              as="a"
              href={
                process.env.REACT_APP_SITE_URL +
                "/user/" +
                (user && user.username)
              }
              position="relative"
              display="block"
              mt={-22}
              mb="spacing.2"
              p="spacing.1"
              borderRadius={9999}
              bg="gray.0"
            >
              <Avatar
                name={user && user.name}
                image={
                  user &&
                  user.profilePicture &&
                  resizeCloudinaryUrl(user.profilePicture.url, "xxxs")
                }
                size={40}
              />
            </Box>
            <a
              href={
                process.env.REACT_APP_SITE_URL +
                "/user/" +
                (user && user.username)
              }
              style={{ display: "block", textDecoration: "none" }}
            >
              <Heading
                as="div"
                size={500}
                textAlign="center"
                color="gray.9"
                style={{ ...ellipsis(), display: "block" }}
              >
                {user && user.name}
              </Heading>
              <Text
                as="div"
                size={300}
                textAlign="center"
                color="gray.7"
                style={{ ...ellipsis(), display: "block" }}
              >
                {"@" + (user && user.username)}
              </Text>
            </a>
          </Flex>
          <Box mt="layout.1">
            <Item
              icon={mdiAccountOutline}
              label="My Account"
              link={process.env.REACT_APP_SITE_URL + "/account"}
            />
            <Item
              icon={mdiFileDocumentBoxOutline}
              label="My Donations"
              link={process.env.REACT_APP_SITE_URL + "/donations"}
            />
            {/* <Item icon={mdiCurrencyUsd} label="My Billing" /> */}
            {/* <MenuItem
              as="div"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <Flex
                as="label"
                justifyContent="flex-start"
                alignItems="center"
                p="layout.1"
                borderRadius={6}
              >
                <Box mr="layout.1" p="6px" borderRadius={9999} bg="gray.3">
                  <Icon
                    symbol={mdiMoonWaningCrescent}
                    size={[16, 20]}
                    color="gray.8"
                  />
                </Box>
                <Toggle size={400} width="100%" small>
                  Dark Mode
                </Toggle>
              </Flex>
            </MenuItem> */}
            <Item icon={mdiLogout} label="Log Out" onClick={logout} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default UserMenu;
