import React from "react";
import { Flex, Spinner } from "@tillen/fresco";

function Loading() {
  return (
    <Flex justifyContent="center" alignItems="center" width="100%" height={256}>
      <Spinner size={64} />
    </Flex>
  );
}

export default Loading;
