import React from "react";
import { Box, Flex } from "@tillen/fresco";

import { TillenLogoGlyph, UserMenu, Wrapper } from "components";

function Header() {
  return (
    <Wrapper as="header" py={["layout.2", "layout.4"]}>
      <Flex alignItems="center" justifyContent="space-between">
        {/* Menu */}
        {/* <Menu /> */}
        {/* User */}
        <Flex>
          <Box as="a" mr="layout.2" href={process.env.REACT_APP_SITE_URL}>
            <TillenLogoGlyph height={36} />
          </Box>
          <UserMenu />
        </Flex>
      </Flex>
    </Wrapper>
  );
}

export default Header;
