import React from "react";
import { Box, Flex, Icon, Text } from "@tillen/fresco";
import { Link } from "react-router-dom";
import { mdiFacebook, mdiInstagram, mdiTwitter } from "@mdi/js";

import { Wrapper } from "../../wrapper";

function PageLinks({ ...rest }) {
  return (
    <Box {...rest}>
      <Flex
        css={{
          "> *:not(:last-of-type)": {
            marginRight: 16
          },
          a: { textDecoration: "none", color: "inherit" }
        }}
      >
        <Text color="gray.6">
          <Link to="/legal">Legal</Link>
        </Text>
        <Text color="gray.6">
          <Link to="/support">Support</Link>
        </Text>
        <Text color="gray.6">
          <Link to="/contact">Contact Us</Link>
        </Text>
      </Flex>
    </Box>
  );
}

function SocialLinks({ ...rest }) {
  return (
    <Box {...rest}>
      <Flex m="-14px">
        <Box
          as="a"
          title="Twitter"
          href="https://twitter.com/TillenCo"
          target="_blank"
          rel="noopener noreferrer"
          p="spacing.4"
        >
          <Icon label="Twitter" symbol={mdiTwitter} size={24} color="gray.5" />
        </Box>
        <Box
          as="a"
          title="Instagram"
          href="https://www.instagram.com/TillenCo/"
          target="_blank"
          rel="noopener noreferrer"
          p="spacing.4"
        >
          <Icon
            label="Instagram"
            symbol={mdiInstagram}
            size={24}
            color="gray.5"
          />
        </Box>
        <Box
          as="a"
          title="Facebook"
          href="https://www.facebook.com/TillenCo/"
          target="_blank"
          rel="noopener noreferrer"
          p="spacing.4"
        >
          <Icon
            label="Facebook"
            symbol={mdiFacebook}
            size={24}
            color="gray.5"
          />
        </Box>
      </Flex>
    </Box>
  );
}

function Footer() {
  return (
    <Wrapper as="footer" pt="layout.4" pb="layout.6">
      <Flex
        flexDirection={["column-reverse", null, "row"]}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text color="gray.6">© {new Date().getFullYear()} Tillen, Inc.</Text>
        <PageLinks my={["layout.2", null, 0]} />
        <SocialLinks />
      </Flex>
    </Wrapper>
  );
}

export default Footer;
