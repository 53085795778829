import React from "react";
import { Menu, MenuList, MenuButton, MenuLink } from "@reach/menu-button";
import { Global } from "@emotion/core";
import css from "@styled-system/css";
import { useLocation, Link } from "react-router-dom";

import { Badge, Box, Flex, Heading, Icon, Text } from "@tillen/fresco";
import { mdiMenu } from "@mdi/js";

import { useAuth } from "contexts";

import {
  AdminLogoGlyph,
  BuildLogoGlyph,
  MatchLogoGlyph,
  RaiseLogoGlyph,
  TillenLogoGlyph
} from "../../logos";

function Item({
  glyph,
  label,
  description,
  link,
  current,
  comingSoonBadge,
  newBadge,
  internalBadge
}) {
  return (
    <MenuLink>
      <Box
        as={link && link.startsWith("http") ? "a" : Link}
        href={link}
        to={link}
        style={{ textDecoration: "none" }}
      >
        <Flex
          alignItems="center"
          px="layout.1"
          py="spacing.4"
          borderRadius={6}
          bg={current ? "rgba(102, 102, 107, 0.075)" : "transparent"}
          css={{ transition: "background-color 200ms ease-out" }}
        >
          {glyph}
          <Box flex={1} px="layout.1">
            <Heading size={500} color="gray.9">
              {label}
              {newBadge && (
                <Badge
                  intent="success"
                  appearance="solid"
                  ml="spacing.2"
                  fontSize={10}
                  style={{ transform: "translateY(-2px)" }}
                >
                  New
                </Badge>
              )}
              {comingSoonBadge && (
                <Badge
                  intent="primary"
                  ml="spacing.2"
                  fontSize={10}
                  style={{ transform: "translateY(-2px)" }}
                >
                  Coming soon
                </Badge>
              )}
              {internalBadge && (
                <Badge
                  intent="default"
                  appearance="solid"
                  ml="spacing.2"
                  fontSize={10}
                  style={{ transform: "translateY(-2px)" }}
                >
                  Internal
                </Badge>
              )}
            </Heading>
            <Text size={300} color="gray.7">
              {description}
            </Text>
          </Box>
        </Flex>
      </Box>
    </MenuLink>
  );
}
const ELEMENT_HEIGHT = 36;

function MenuComp() {
  const location = useLocation();
  const { user } = useAuth();

  const APPS = [
    {
      label: "Charities",
      link: "/",
      current:
        location.pathname === "/" ||
        location.pathname.startsWith("/edit-charity")
    },
    {
      label: "Causes",
      link: "/causes",
      current:
        location.pathname.startsWith("/causes") ||
        location.pathname.startsWith("/edit-cause")
    },
    {
      label: "Donations",
      link: "/donations",
      current: location.pathname.startsWith("/donations")
    },
    {
      label: "Reporting",
      link: "/reporting",
      current: location.pathname.startsWith("/reporting")
    },
    {
      label: "Home",
      // description: "Give easily to any charity in Canada",
      link: process.env.REACT_APP_SITE_URL,
      glyph: <TillenLogoGlyph height={ELEMENT_HEIGHT} />
    },
    {
      label: "Raise",
      description: "Start or join a fundraiser",
      link: process.env.REACT_APP_RAISE_URL,
      glyph: <RaiseLogoGlyph height={ELEMENT_HEIGHT} />,
      newBadge: true
    },
    {
      label: "Match",
      description: "Match employee donations",
      link: process.env.REACT_APP_MATCH_URL,
      glyph: <MatchLogoGlyph height={ELEMENT_HEIGHT} />,
      comingSoonBadge: true
    },
    {
      label: "Build",
      description: "Add donations to your app",
      link: process.env.REACT_APP_BUILD_URL,
      glyph: <BuildLogoGlyph height={ELEMENT_HEIGHT} />,
      comingSoonBadge: true
    },
    user &&
      user.admin && {
        label: "Admin",
        description: "Management dashboard",
        link: "/",
        glyph: <AdminLogoGlyph height={ELEMENT_HEIGHT} />,
        internalBadge: true,
        current: true
      }
  ];

  return (
    <>
      {/* Styles for menu */}
      <Global
        styles={css({
          "[data-reach-menu-button]": {
            cursor: "pointer",
            outline: "none"
          },
          "[data-reach-menu-list]": {
            outline: "none"
          },
          "[data-reach-menu-items]": {
            cursor: "pointer",
            outline: "none"
          },
          "[data-reach-menu-link]": {
            outline: "none"
          },
          "[data-reach-menu-link][data-selected]": {
            "> *": { boxShadow: "elevations.0" }
          }
        })}
      />

      {/* Component */}
      <Box as={Menu}>
        <Flex
          alignItems="center"
          as={MenuButton}
          bg="transparent"
          border="none"
        >
          <Box mr="layout.1">
            <Icon symbol={mdiMenu} height={ELEMENT_HEIGHT - 12} />
          </Box>
          <AdminLogoGlyph height={ELEMENT_HEIGHT} />
          <Heading
            as="span"
            size={600}
            ml="layout.1"
            lineHeight={ELEMENT_HEIGHT + "px"}
            color="gray.9"
          >
            Admin
          </Heading>
        </Flex>
        <Box
          as={MenuList}
          mt="layout.1"
          // ml={["-12px", "-16px"]}
          p="layout.1"
          borderRadius={8}
          bg="gray.0"
          boxShadow="elevations.4"
        >
          {APPS.map((app, i) => {
            if (app) {
              return (
                <Item
                  glyph={app.glyph}
                  label={app.label}
                  description={app.description}
                  link={app.link}
                  current={app.current}
                  newBadge={app.newBadge}
                  comingSoonBadge={app.comingSoonBadge}
                  internalBadge={app.internalBadge}
                  key={"appMenuItems" + i}
                />
              );
            } else return null;
          })}
        </Box>
      </Box>
    </>
  );
}

export default MenuComp;
