const stripUrl = text => {
  text = text
    .replace(/[^a-z0-9\s-]/gi, "")
    .trim()
    .replace(/\s+/g, "-")
    .toLowerCase();
  return text;
};

export default stripUrl;
