import React from "react";
import { Box } from "@tillen/fresco";

function TillenLogoGlyph({ height, ...rest }) {
  return (
    <Box
      as="svg"
      width={height}
      height={height}
      viewBox="0 0 32 32"
      verticalAlign="top"
      {...rest}
    >
      <title>Tillen</title>
      <rect width={32} height={32} rx={16} fill="#F2CF27" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.075 21.375C28.867 27.568 22.95 32 16 32 9.05 32 3.133 27.568.925 21.375 3.133 15.182 9.05 10.75 16 10.75c6.951 0 12.867 4.432 15.075 10.625z"
        fill="#F5DA59"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.851 26.75A15.959 15.959 0 0116.001 32a15.959 15.959 0 01-11.852-5.25A15.959 15.959 0 0116 21.5c4.698 0 8.924 2.025 11.851 5.25z"
        fill="#F9EA9F"
      />
    </Box>
  );
}

TillenLogoGlyph.defaultProps = {
  height: 48
};

export default TillenLogoGlyph;
