import React, { useState } from "react";

import { Avatar, Flex, IconButton, Text } from "@tillen/fresco";

import { resizeCloudinaryUrl, stripUrl } from "utils";

// Component to display the chosen charity when it is eventually picked by user
function SmallCard({ onClick, name, icon, logo }) {
  return (
    <Flex
      mb="layout.1"
      alignItems="center"
      justifyContent="space-between"
      css={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Flex mr="layout.2 " alignItems="center">
        <Avatar
          image={logo && logo.url && resizeCloudinaryUrl(logo.url, "xxxs")}
          size={32}
          name={name}
        />
        <Text children={name} ml="layout.1" />
      </Flex>
      <IconButton appearance="minimal" icon={icon} size={24} round />
    </Flex>
  );
}

export default SmallCard;
